// @TODO: Move this file to be attached to the cart_confirm_v1 template, OR
//        figure out a way to not hard-code the template name on line 9
(function ($, site, generic) {
  window.site = site || {};
  var settings = Drupal.settings || {};
  var settingsLoyalty = typeof settings.loyalty === 'object' ? settings.loyalty : {};
  site.cartConfirm = site.cartConfirm || {};
  site.template = site.template || {};

  // 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
  function _normalizeResponseKeys(items) {
    var replaceKey = function (key) {
      return key.replace(/\./, '_');
    }
    var normalizeArray = function (array) {
      var newArray = [];
      for (var x = 0, alen = array.length; x < alen; x++) {
        var innerObj = {};
        if (typeof (array[x]) == 'string') {
          // When the array just has a string, just push that.
          newArray.push(array[x]);
        }
        else {
          // When the array is anything else, just push that.
          for (var innerKey in array[x]) {
            innerObj[replaceKey(innerKey)] = array[x][innerKey];
          }
          newArray.push(innerObj);
        }
      }
      return newArray;
    }
    var out = [];
    for (var i = 0, len = items.length; i < len; i++) {
      out[i] = {};
      for (var key in items[i]) {
        if (items[i].hasOwnProperty(key)) {
          // Need to go deeper for collection items.
          if ($.isArray(items[i][key])) {
            out[i][replaceKey(key)] = normalizeArray(items[i][key]);
          }
          else {
            out[i][replaceKey(key)] = items[i][key];
          };
        }
      }
    }

    return out;
  }

  site.cartConfirm.launch = function (args) {
    var $body = $('body');
    var template = 'bobbi_cart_confirm_v1';
    var $cartConfirm = $('.site-utils__menu .cart_overlay');
    var $quickshopVisible = $('.js-quickshop__container:visible');
    var trans = args.trans;
    var order = trans.order;
    var cartItems = args.items.concat(order.samples);
    var offerCodes = (typeof order.offerCodes !== 'undefined') ? order.offerCodes : trans.offerCodes;
    var totals = (typeof trans.trans !== 'undefined') ? trans.trans.totals : trans.totals;
    var templateData = { items: [] };
    var cartItem;
    var isCr22 = Drupal.settings.global_variables && Drupal.settings.global_variables.cr22;

    cartItems = _normalizeResponseKeys(cartItems);
    // Mark the default sku for a kit/bundle
    // Note, this should normally be done by PG.
    $.each(cartItems, function (i, item) {
      if (!item.isBundleItem) {
        return;
      }
      $.each(item.items, function (i, subitem) {
        if (subitem['sku_SKU_BASE_ID'] == item['sku_SKU_BASE_ID']) {
          subitem['isDefaultKitSku'] = 1;
        }
      });
    });

    templateData.formattedSubtotal = trans.formattedSubtotal;
    templateData.items_count = trans.items_count;

    // Get Template html
    var conf_html = site.template.get({
      name: template,
      data: { items: cartItems }
    });
    $cartConfirm.html(conf_html);

    $('a.close', $cartConfirm).click(function (e) {
      if (isCr22) {
        e.preventDefault();
      }
      $(this).closest('li').find('.site-utils__link').focus().attr('aria-expanded', 'false');
      $cartConfirm.addClass('hidden');
      $quickshopVisible.find('.js-add-to-cart').focus();
      site.restrict_navigation($quickshopVisible);
    });

    if (!templateData.items_count - 0) {
      templateData.items_count = '';
    }
    else if (templateData.items_count !== 1) {
      $('.subtotal .items', $cartConfirm).addClass('plural');
    }
    if (isCr22) {
      $body.removeClass('site-header-formatter--hidden');
    }
    $(document).on('close_cart_confirm_overlay', function () {
      $('.js-cart-overlay-close', $cartConfirm).trigger('click');
    });
    // update bag icon count
    $('.site-utils__item--cart .js-cart_count').html(templateData.items_count);
    // overlay cart count
    $('[data-pg-prop="items_count"]', $cartConfirm).html(templateData.items_count);

    // If an item was just added, just show that item
    // $('.full_view, .add_view', $cartConfirm).removeClass('hidden'); // **NO** per BB-222

    // Set Full Width for Mobile Device
    // TODO: this should be done with CSS, NOT JS
    var $cart_wrapper = $('.cart-confirm-wrapper');
    var buffer = 15;
    if (($cart_wrapper.length > 0) && site.client.isMobile) {
      $cart_wrapper.width($(window).width() - buffer);
    }

    if (typeof args.newItemProdId !== 'undefined') {
      $('[data-product_id="' + args.newItemProdId + '"][data-sku_id="' + args.newItemSkuId + '"]', $cartConfirm)
        .first()
        .addClass('last-item-added')
        .prependTo('.full_view .cart-products');
      // set success msg
      $('.add_view .added_count').html(args.newItemQty);
      $('.add_view .added_msg').html(
        (args.newItemQty === 1) ? $('[data-localtrans="cart_confirm_success_msg_singular"]').html() : $('[data-localtrans="cart_confirm_success_msg_plural"]').html()
      );
      // add a copy of the newly added product row to the "add view"
      $('.add_view .cart-products').html($('[data-product_id="' + args.newItemProdId + '"][data-sku_id="' + args.newItemSkuId + '"]', $cartConfirm).clone().detach());

      //Hide QTY of the newly added product
      var $ele = $('.add_view .cart-products').find('.qty');
      if ($ele.length > 0) {
        $ele.hide();
      }

      //Hide Checkout button of the newly added product
      $ele = $('.add_view .checkout').find('a.button');
      if ($ele.length > 0) {
        $ele.hide();
      }

      // hide full view
      // $('.full_view', $cartConfirm).addClass('hidden'); // **NO** per BB-222

      // set add-item view on a 10 second timer
      setTimeout(function () {
        if ($('.full_view', $cartConfirm).hasClass('hidden')) {
          $('.site-utils__item--cart .cart_overlay').addClass('hidden');
        }
      }, 10000);
    } else { // hide add-item view (show full view)
      $('.add_view', $cartConfirm).addClass('hidden');
    }

    // add any applicable cart_overlay_promos
    /* TODO: make an array of promos possible again with new translation framework */
    if (settingsLoyalty.show_cart_overlay_promo_only_loyalty) {
      if (!!parseInt(site.userInfoCookie.getValue('is_loyalty_member'))) {
        $('.full_view .promos').html(site.template.get({ name: 'cart_overlay_promo_v1' }));
      }
    } else {
      $('.full_view .promos').html(site.template.get({ name: 'cart_overlay_promo_v1' }));
    }

    var $promo = $('.js-promo .free_ship').html();
    var $promoChild = $('.js-promo .free_ship').children().html();

    if ($promo === '' || $promoChild === '') {
      $('.js-promo').hide();
    }
    //offerCodes.push('default_free_ship');
    //for(var j = 0; j < offerCodes.length; j++){
    //  $('.full_view .promos').append($('script.inline-template[path="cart_confirm_promo_'+offerCodes[j]+'"]').html());
    //}

    var subtotalDecimalLength = settings.decimal_disable ? 0 : 2;
    var subtotal = parseFloat(totals.PRODUCT_SUM_W_TAX).toFixed(subtotalDecimalLength);
    if (settings.decimal_point_replace) {
      subtotal = subtotal.replace('.', ',');
    }
    $('.subtotal .sub_t .sub_num', $cartConfirm).html(subtotal);

    // initialize custom scrollpane (buggy at the moment...)
    // $('.cart-products', $cartConfirm).first().jScrollPane();

    // close confirm overlay on scroll

    $(window).scroll(function () {
      if (!$cartConfirm.hasClass('hidden')) {
        $(this).closest('li').find('.site-utils__link').focus().attr('aria-expanded', 'false');
        $cartConfirm.addClass('hidden');
        $quickshopVisible.find('.js-add-to-cart').focus();
        site.restrict_navigation($quickshopVisible);
      }
    });

    // all set, show overlay
    $cartConfirm.removeClass('hidden');
    $('.js-cart_overlay').removeClass('hidden').focus();
    site.restrict_navigation($('.js-cart_overlay'));
  };

  Drupal.behaviors.cartConfirm = {
    attach: function (context, settings) {

      var container = $(context).find('.site-utils__item--cart');
      var cartLink = container.find('.site-utils__link');
      var cartOverlay = container.find('.cart_overlay');

      /* cart event */
      $(document).on('addToCart.success', function (e, cartResult) {
        generic.overlay.hide();

        if (_.isUndefined(cartResult.trans_data) || _.isUndefined(cartResult.ac_results)) {
          return;
        }

        var args = {
          trans: cartResult.trans_data,
          items: cartResult.trans_data.order.items,
          newItemProdId: cartResult.ac_results[0].result.CARTITEM['prod.PRODUCT_ID'],
          newItemSkuId: cartResult.ac_results[0].result.CARTITEM['sku.SKU_ID'],
          newItemQty: Math.max(1, cartResult.ac_results[0].result.CARTITEM.ITEM_QUANTITY - cartResult.ac_results[0].result.PREVIOUS_ITEM_QUANTITY)
        };

        if (settings.use_trans_get_in_addtocart_success_overlay) {
          generic.jsonrpc.fetch({
            'method': 'trans.get',
            'params': [{
              'trans_fields': ['TRANS_ID', 'totals'],
              'payment_fields': [],
              'order_fields': ['items', 'samples', 'offerCodes']
            }],
            'onSuccess': function (d) {
              var cartResponse = d.getValue();
              if (typeof cartResponse === 'undefined' || !cartResponse) {
                return;
              }

              args.trans = cartResponse;
              args.items = cartResponse.order.items;

              site.cartConfirm.launch(args);
            },
            onError: function () { }
          });
        } else {
          site.cartConfirm.launch(args);
          if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.loyalty_prices_enabled) {
            $(document).trigger('gnav.cart.overlay');
          }
        }
      });

      $(document).on('addToCart.failure', function (e, errorObjects) {
        var errorText = '';
        $.each(errorObjects, function (i, msg) {
          errorText += msg.text + '<br />';
        });
        generic.overlay.launch({
          content: '<div class="system-message">' + errorText + '</div>',
          className: 'colorbox--system-message',
          cssStyle: {
            width: '300px',
            height: 'auto'
          }
        });
      });

      /* mouseover icon event */
      var loading = false;
      if (site.client.isMobile) {
        cartLink.once().click(function (event) {
          window.location = '/checkout/viewcart.tmpl';
        });
      } else {
        container.hover(function (e) {
          site.restrict_navigation(cartOverlay);
          if (cartOverlay.html() === '' && !loading) {
            // no cart drawn yet, check user to see if items in cart, if so, make a trans rpc call...
            var item_count = site.userInfoCookie.getValue('item_count');
            if (item_count > 0) {
              // show loading icon while we wait for the rpc response
              var conf_loading = site.template.get({ name: 'bobbi_cart_confirm_v1', data: { loading: true } });
              cartOverlay.find('.site-utils__menu').html(conf_loading);
              loading = true;

              generic.jsonrpc.fetch({
                "method": "trans.get",
                "params": [{
                  "trans_fields": ["TRANS_ID", "totals"],
                  "payment_fields": [],
                  "order_fields": ["items", "samples", "offerCodes"]
                }],
                "onSuccess": function (d) {
                  loading = false;

                  var cartResponse = d.getValue();
                  if ((typeof (cartResponse) === 'undefined') || !cartResponse) {
                    return;
                  }

                  var args = {
                    trans: cartResponse,
                    items: cartResponse.order.items
                  };

                  site.cartConfirm.launch(args);
                  if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.loyalty_prices_enabled) {
                    $(document).trigger('gnav.cart.overlay');
                  }
                },
                onError: function () {
                  loading = false;
                }
              });
            }
          } else if (cartOverlay.hasClass('hidden')) {
            // show the cart that's already there
            cartOverlay.find('.full_view, .add_view').removeClass('hidden');
            cartOverlay.find('.add_view').addClass('hidden');
            cartOverlay.removeClass('hidden');
          }
        }, function () {
          cartOverlay.addClass('hidden');
        });
      }// breakpoint

      var $target = $('.js-site-utils__link a');
      $target.keydown(function (e) {
        if (window.site.getKeycode(e) === 1 || window.site.getKeycode(e) === 13) {
          e.preventDefault();
          $(this).attr('aria-expanded', 'true');
          cartLink.off('click');
          container.trigger('mouseover');
          $('.js-cart_overlay').focus();
        }
        if (e.shiftKey) {
          $(this).parent().removeClass('active');
          $(this).attr('aria-expanded', 'false');
        }
      });
      site.userInfoCookie.init();
    }
  };
})(jQuery, window.site || {}, window.generic || {});
